export const EXCERCISE_TYPES = {
  MOEE: "moee",
  FWEE: "fwee",
  SOEE: "soee",
};

/**
 * supported dossier types
 */
export const DOSSIER_TYPES = {
  SYSTEM: "system",
  ZONAL: "zonal",
  STRUCTURE: "structure",
};