import { cloneDeep } from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import {
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@airbus/components-react";
import { Delete, Edit } from "@airbus/icons/react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateDossierStats } from "../../models/dossierModel/dossierStatusActions";
import idx from "idx";
import { getData } from "../../utils/data.request";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import NoData from "../NoData/NoData";
import { FeatureToggle } from "react-feature-toggles";
import { FEATURES } from "../../config/feature.toggles";
import "./TableComponent.scss";
import TableEditable from "./TableEditable";
import TableModify from "./TableModify";
import {
  getSectionKey,
  checkDataInStorage,
  deleteFormTable,
} from "../../utils/localStorageHelper";
import {
  prepareData,
  getLabel,
  getStyle,
  formatDataWithLS,
  formatDataWithLSEdit,
} from "./TableUtils";
import { setSaveStatus } from "../../models/teEditableModel/teEditableAction";
import { ROLES, STATUS } from "../../config/dossier.status";
import { DERIVED_SYNC_STATUS } from "../../config/dossier.status";
import { DOSSIER_TYPES, EXCERCISE_TYPES } from "../../lib/constants";

const TableComponent = (props) => {
  const { reportName } = useParams();
  const dispatch = useDispatch();
  const { sources, details } = idx(props, (_) => _.content);
  const { tableStyles, labels, title, allowedEdits } = idx(details, (_) => _);
  const { hasIndex } = idx(tableStyles, (_) => _);
  const { dossierStatus, userData, syncNotification, dossierStats} =
    useSelector((store) => store);
  const maintenanceProgram =
    dossierStats &&
    dossierStats.dossier &&
    dossierStats.dossier.data &&
    dossierStats.dossier.data.titleInfo &&
    dossierStats.dossier.data.titleInfo[0]?.mpdTaskMaintenanceProgram;
  const { dossierKey, dossierType, exerciseType, filters } = dossierStatus;
  const section_level_key = getSectionKey(sources, details.title, filters);
  const report_level_key = `TextEdit_${dossierType}_${exerciseType}_${dossierKey}`;
  const [state, setState] = useReducer(
    (currState, newState) => ({ ...currState, ...newState }),
    { columns: [], data: [], error: null }
  );
  const [formattedTableData, setFormattedTableData] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  //edit button will be displayed on 3 conditions
  // 1. if user has specialist role 2. dossier status is TE 3. sync is not in progress
  const displayEdit =
    ROLES.onlySpecialist.indexOf(userData.user.role) > -1 &&
    STATUS.TECHNICAL_EVALUATION === dossierStatus.status &&
    syncNotification.sync_info &&
    syncNotification.sync_info.status !== DERIVED_SYNC_STATUS.IN_PROGRESS;

  useEffect(() => {
    getData(sources, reportName)
      .then((response) => {
        response && setState(prepareData(response, details));
        response &&
          response.data &&
          response.data.length &&
          dispatch(updateDossierStats(reportName, response));
      })
      .catch((err) => {
        setState({ error: err });
      });
  }, [sources, reportName, details, dispatch]);
  const { columns, data, error } = state;

  useEffect(() => {
    const tabData = cloneDeep(data);
    const formattedData = checkDataInStorage(
      report_level_key,
      section_level_key
    ).isDataAvailable
      ? section_level_key.includes("Record_of_Revision") ||
        section_level_key.includes("Task_History")
        ? formatDataWithLSEdit(tabData, report_level_key, section_level_key)
        : formatDataWithLS(
            tabData,
            report_level_key,
            section_level_key,
            allowedEdits
          )
      : tabData;

    setFormattedTableData(formattedData);
  }, [data, report_level_key, reRender, allowedEdits, section_level_key]);

  const handleReRender = () => {
    setReRender(!reRender); // state change will re-render parent
  };

  const deleteRow = (selectedRow) => {
    const columnName =
      title === "Record of Revision" ? "revision" : "historyNumber";

    const updatedData = formattedTableData.filter(
      (item) => item[columnName] !== selectedRow[columnName]
    );
    deleteFormTable(
      report_level_key,
      section_level_key,
      selectedRow,
      columnName,
      updatedData
    );
    dispatch(setSaveStatus(report_level_key))
    setFormattedTableData(updatedData);
  };

  const hadleClosePopup = () => {
    setIsEdit(false);
    setSelectedRow({});
  };

  const editRow = (rowData) => {
    setIsEdit(true);
    setSelectedRow(rowData);
  };

  const renderTableHeader = () => {
    return !details.pivot ? (
      <TableRow>
        {!!hasIndex && (
          <TableCell style={getStyle(null, tableStyles, "head")}>
            Index
          </TableCell>
        )}
        {columns.map((column) => {
          const columnLabel = getLabel(column, labels);
          return column !== 'historyNumber' && column !=='ffr_100_pc' && (columnLabel || columnLabel === '') &&
          <TableCell key={column} style={getStyle(column, tableStyles, "head")}>
            {columnLabel}
          </TableCell>
  })}
      </TableRow>
    ) : (
      <React.Fragment>
        <TableRow>
          <TableCell
            colSpan={1}
            rowSpan={2}
            style={getStyle(details.pivot.row, tableStyles, "head")}
          >
            {getLabel(details.pivot.row, labels)}
          </TableCell>
          <TableCell
            colSpan={columns.length - 1}
            style={getStyle(details.pivot.column, tableStyles, "head")}
          >
            {getLabel(details.pivot.column, labels)}
          </TableCell>
        </TableRow>
        <TableRow>
          {columns
            .filter((column) => column !== details.pivot.row)
            .map((column) => (
              <TableCell
                key={column}
                style={getStyle(column, tableStyles, "head")}
              >
                {getLabel(column, labels)}
              </TableCell>
            ))}
        </TableRow>
      </React.Fragment>
    );
  };
  const renderTableBody = () => {

    return formattedTableData.length ? (
      formattedTableData.map((row, index) => (
        <TableRow key={index} style={{...getStyle(null, tableStyles, "row")}}>
          {!!hasIndex && (
            <TableCell style={getStyle(null, tableStyles, "body")}>
             <div>
              {index + 1}
              </div>
            </TableCell>
          )}
          {columns.map((column) => {
            const condition = labels.some(e => e.key === column && column !== 'ffr_100_pc');
            const isPrimaryInterval = row.ffr_100_pc && row.ffr_100_pc !== 'null' ;
            return column !== 'historyNumber' && condition &&
            <TableCell
              key={column}
              style={{...getStyle(column, tableStyles, "body"), color : isPrimaryInterval ? '#E55BA8' : '', fontWeight : isPrimaryInterval ? 'bold' : 'normal'}}
              className={`${(title === "Task History" || title === "Record of Revision") ? 'editable-column' : ''}`}
            >
             <div>
              {title === 'Consolidated Task' && column === 'INTERVAL' && !(row[column]) ? dossierStats.dossier.data['Current Interval'] : row[column]}
             </div>
            </TableCell>
          })}
          {(title === "Task History" || title === "Record of Revision") &&
            formattedTableData[0] &&
            formattedTableData[0].isDisabled !== "true" && displayEdit 
            && dossierType === DOSSIER_TYPES.SYSTEM 
            && exerciseType === EXCERCISE_TYPES.FWEE &&
            (
              <>
                <IconButton
                variant="ghost"
                  className={"ror-th-btn"}
                  onClick={() => editRow(row)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                variant="ghost"
                  className={"ror-th-btn ror-th-btn-del"}
                  onClick={() => deleteRow(row)}
                >
                  <Delete />
                </IconButton>
              </>
            )}
        </TableRow>
      ))
    ) : (
      <NoData columns={columns} hasIndex={hasIndex} sources={sources} />
    );
  };
  const tableEditprops = {
    data: formattedTableData,
    labels,
    section_level_key,
    report_level_key,
    handleReRender,
    title,
    allowedEdits,
    dossierType,
    maintenanceProgram
  };
  return (
    <>
      {displayEdit && (
        <FeatureToggle featureName={FEATURES.EDITABLE_TABLE}>
          {allowedEdits &&
            (title === "Task History" || title === "Record of Revision")
            && dossierType === DOSSIER_TYPES.SYSTEM 
            && exerciseType === EXCERCISE_TYPES.FWEE
            &&
            (
              <TableModify
                {...tableEditprops}
                isEdit={isEdit}
                selectedRow={selectedRow}
                onClose={hadleClosePopup}
              />
            )}
          {allowedEdits &&
            formattedTableData.length !== 0 &&
            title !== "Task History" &&
            title !== "Record of Revision" && (
              <TableEditable {...tableEditprops} />
            )}
        </FeatureToggle>
      )}
      <Container
        className={
          formattedTableData.length &&
          formattedTableData[0].isDisabled === "true"
            ? "table-component-cls grayedBackground exclude-print"
            : "table-component-cls"
        }
      >
        {!error ? (
          !(formattedTableData.length === 0 && details.noDataHide) ? (
            <Table
              className="table"
              style={{ tableLayout: details.tableLayout }}
            >
              <TableHead className="table-head">
                {renderTableHeader()}
              </TableHead>
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          ) : null
        ) : (
          <ErrorHandler error={error} />
        )}
      </Container>
    </>
  );
};

export default TableComponent;
