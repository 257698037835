import React from "react";
import { render } from "react-dom";
import AuthenticatedApp from "./containers/AuthenticatedApp/AuthenticatedApp";
import { Provider } from "react-redux";
import { ThemeProvider } from "@airbus/components-react";
import { FeatureToggleProvider } from 'react-feature-toggles';
import { store } from "./store/store";
import "typeface-roboto";

import { getFeatureToggleList } from './config/feature.toggles';
import "./index.scss";

import { PostHogProvider } from "posthog-js/react";
import { posthog } from "posthog-js";

// analytic url with origin 
const ANALYTICS_URL= `https://${process.env.REACT_APP_ANALYTICS_URL}/logs`

posthog.init(
  process.env.REACT_APP_ANALYTICS_KEY,
  {
    api_host: ANALYTICS_URL,
  }
);

posthog.register({
  'airbus-aspire-code': process.env.REACT_APP_ASPIRE,
  'airbus-app-env': process.env.REACT_APP_STAGE,
});

const featureToggleList = getFeatureToggleList();
const rootElement = document.getElementById("root");
render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
  <ThemeProvider brand="skywise">
    <FeatureToggleProvider featureToggleList={featureToggleList}>
      <Provider store={store}>
        <AuthenticatedApp />
      </Provider>
    </FeatureToggleProvider>
  </ThemeProvider>
  </PostHogProvider>
</React.StrictMode >,
  rootElement
);
