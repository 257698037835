import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogHeader,
  Typography,
  Textarea,
  DatePicker,
  Button,
} from "@airbus/components-react";
import { Visibility, Restore } from "@airbus/icons/react";
import {
  saveUpdatedStorageDataForTable,
  getEditsFromLocalStorage,
  saveEditsToLocalStorage,
  clearEditsFromLocalStorage,
} from "../../utils/localStorageHelper";
import {
  setSaveStatus,
  setTEDisabledColumns,
} from "../../models/teEditableModel/teEditableAction";
import "./TableComponent.scss";
import { ED_DISABLE_TYPE } from "../../config/dossier.status";

const TableModify = (props) => {
  const {
    data,
    section_level_key,
    report_level_key,
    handleReRender,
    title,
    allowedEdits,
    dossierType,
    isEdit,
    selectedRow,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [isPreviewDisable, setisPreviewDisable] = useState(
    isEdit && Object.keys(selectedRow).length !== 0 ? false : true
  );
  const [editedTableData, setEditedTableData] = useState({});
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (title === "Task History") {
      data[0] &&
        data[0].isDisabled === "true" &&
        dispatch(
          setTEDisabledColumns({
            type: ED_DISABLE_TYPE.INIT,
            data: ["Task History"],
            section: section_level_key.split("@")[1],
          })
        );
    } 
  }, [
    data,
    report_level_key,
    dossierType,
    allowedEdits,
    title,
    section_level_key,
    dispatch,
  ]);

  useEffect(() => {
    if (title === "Record of Revisions") {
      data[0] &&
        data[0].isDisabled === "true" &&
        dispatch(
          setTEDisabledColumns({
            type: ED_DISABLE_TYPE.INIT,
            data: ["Record of Revisions"],
            section: section_level_key.split("@")[1],
          })
        );
    } 
  }, [
    data,
    report_level_key,
    dossierType,
    allowedEdits,
    title,
    section_level_key,
    dispatch,
  ]);

  useEffect(() => {
    const colName =
      title === "Task History" ? "Reason for Change" : "Change Description";
    if (
      editedTableData.Date &&
      editedTableData.Date !== "" &&
      editedTableData[colName] &&
      editedTableData[colName] !== ""
    ) {
      setisPreviewDisable(false);
    } else {
      setisPreviewDisable(true);
    }
  }, [
    title,
    editedTableData,
  ]);

  const handleOpen = useCallback(() => {
    if (!isEdit) {
      setEditedTableData({});
    }
    setisPreviewDisable(true);
    setOpenDialog(true);
  }, [isEdit]);

  const handleSelectedRow = useCallback(() => {
    handleOpen();
    const colName =
      title === "Task History" ? "Reason for Change" : "Change Description";
    const editedData = {
      [colName] : selectedRow.content,
      Date : new Date(selectedRow.date)
    }
    setEditedTableData(editedData);
  }, [title, selectedRow, handleOpen]);

  useEffect(() => {
    if (isEdit) {
      handleSelectedRow();
    }
  }, [selectedRow, isEdit, title, handleSelectedRow]);

  const handleClose = () => {
    setOpenDialog(false);
    onClose();
    setEditedTableData({});
  };

  const handlePreview = () => {
    saveUpdatedStorageDataForTable(
      report_level_key,
      section_level_key,
      editedTableData,
      data,
      isEdit,
      selectedRow
    );
    dispatch(setSaveStatus(report_level_key));
    handleReRender();
    handleClose();
  };

  const handleReset = () => {
    const storageData = getEditsFromLocalStorage(report_level_key);
      Object.keys(storageData).includes(section_level_key) && delete storageData[section_level_key];
      delete storageData[`deleted_${section_level_key}`];
      Object.keys(storageData).length === 0
        ? clearEditsFromLocalStorage(report_level_key)
        : saveEditsToLocalStorage(report_level_key, storageData);
    handleClose();
    dispatch(setSaveStatus(report_level_key));
    handleReRender();
    setOpen(false);
  };

  const handleRemarksUpdate = (e) => {
    const obj = {...editedTableData}
    const colName =
      title === "Task History" ? "Reason for Change" : "Change Description";
      obj[colName] = e.target.value;
    setEditedTableData(obj);
  };

  const setDate = (value) => {
    const obj = {...editedTableData}
    obj["Date"] = value && value.toString();
    setEditedTableData(obj);
  };

  const renderDialogContentForm = () => {
    const formTableData = () => {
      return (
        <div className="date-picker">
          <Typography variant="medium">
            <div>Date</div>
          </Typography>
          <DatePicker
            onChange={setDate}
            defaultValue={isEdit ? new Date(selectedRow.date) : null} 
          />
        </div>
      );
    };
    return (
      <div>
        {formTableData()}
        {renderCBandRemarks()}
      </div>
    );
  };

  const renderCBandRemarks = () => {
    return (
      <div>
        <Typography variant="medium" className="remarks-typo">
          {title === "Task History"
            ? "Reason for Change"
            : "Change Description"}
        </Typography>
        <Textarea
          multiline={5}
          defaultValue={isEdit ? selectedRow.content : ""}
          id="remarks"
          className="ip-remarks"
          onChange={(e) => handleRemarksUpdate(e)}
        />
      </div>
    );
  };

  const renderPopup = () => {
    return(
      <Dialog 
      open={open} 
      onClose={() => setOpen(false)} 
      className="popup-dialog">
      <DialogHeader>Reset</DialogHeader>
      <DialogContent>
        <p>
        Your are about to reset the {title} section. 
        This action will revert any changes or additions done to any of the 
        entries in {title} will be lost. Please confirm to proceed
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant="primary" onClick={handleReset} className="reset-confirm">
          Confirm
        </Button>
        <Button variant="secondary" onClick={() => setOpen(false)} className="reset-cancel">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    )
  }

  return (
    <>
      <div className="table-modify exclude-print">
          <Tooltip title="Reset">
            <IconButton
              variant="ghost"
              className="reset-btn"
              size="small"
              onClick={() => {
                setOpen(true)
              }}
            >
              <Restore />
            </IconButton>
          </Tooltip>
          <div className="dialog-check">
            {renderPopup()}
          </div>
          <Tooltip title="Add">
            <Button
              variant="ghost"
              className="edit-btn"
              size="small"
              onClick={handleOpen}
            >
              Add new entry
            </Button>
          </Tooltip>
      </div>
      <div>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          className="dialog-edit"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogHeader className="dialog-header">{`${title}`}</DialogHeader>
          <DialogContent>
            {renderDialogContentForm()}
          </DialogContent>
          <DialogActions className="dialog-action">
            <IconButton
              variant="ghost"
              className="preview-btn"
              size="small"
              disabled={isPreviewDisable}
              onClick={handlePreview}
            >
              <Visibility />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

TableModify.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  section_level_key: PropTypes.string.isRequired,
  report_level_key: PropTypes.string.isRequired,
  handleReRender: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  allowedEdits: PropTypes.array.isRequired,
  dossierType: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  selectedRow: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TableModify;
