import _ from "lodash";
import { getData } from "../../utils/data.request";
import { getToCConfig } from "./reportHelper";

export const processTemplate = async (template) => {
  const reportId = window.location.href.split("/").pop();
  if (template.preprocessors && template.preprocessors.length) {
    let templateDoc = JSON.stringify(template, null, 2);
    try {
      for (const processor of template.preprocessors) {
        const {
          name,
          type,
          filter,
          source,
          sections
        } = processor;
        const response = await getData([source], reportId, source.key);
        if (response && response.data) {
          if (type === "replacement") {
            templateDoc = computeReplacement(
              response,
              name,
              source,
              templateDoc
            );
          } else if (type === "filteredReplacement") {
            templateDoc = computeFilteredReplacement(
              response,
              name,
              filter,
              source,
              templateDoc
            );
          } else if (type === "multi") {
            templateDoc = computeMulti(
              response,
              templateDoc,
              sections
            ) || templateDoc;
          } else if(type === "replacementFFRMEField") {
            templateDoc = computeReplacementFFRME(
              response,
              name,
              source,
              templateDoc
            );
          }
        }
      }
    } catch (err) {
      console.log("ref::Err", err);
      return JSON.parse(templateDoc);
    }
    return JSON.parse(templateDoc);
  } else {
    return template;
  }
};
/**
 * Function to replace target string in dossier with the dynamic response
 * @param {Object} response  data from query
 * @param {String} name name of the replacement column
 * @param {String} source query source to get replacement text
 * @param {String} templateDoc JSON string of the template
 * @returns {String} JSON string of the template
 */
const computeReplacement = (response, name, source, templateDoc) => {
  const regex = new RegExp(source.key, "g");
  const replacement =
    response.data.length && response.data[0][name] ?
    response.data[0][name] :
    "NONE";
  templateDoc = templateDoc.replace(regex, replacement);
  return templateDoc;
};

/**
 * Function to replace target string in dossier with the dynamic response
 * @param {Object} response  data from query
 * @param {String} name name of the replacement column
 * @param {String} source query source to get replacement text
 * @param {String} templateDoc JSON string of the template
 * @returns {String} JSON string of the template
 */
const computeReplacementFFRME = (response, name, source, templateDoc) => {


    const regexOldDossier = new RegExp("%%IS_OLD_DOSSIER%%", "g");
    const regexNewDossier = new RegExp("%%IS_NEW_DOSSIER%%", "g");
    const oldDossier = (Object.keys(response.data[0]).length === 0 
      || response.data[0][name] === "7") ? false : true; // false means the old graph will show true means it will hide on UI
    const newDossier = !oldDossier
    templateDoc = templateDoc.replace(regexOldDossier, oldDossier);
    templateDoc = templateDoc.replace(regexNewDossier, newDossier);
 
  return templateDoc;
};

/**
 * Function to replace target string in dossier with the dynamic filtered response
 * @param {Object} response  data from query
 * @param {String} name name of the replacement column
 * @param {String} filter the column from which to compare to the filter
 * @param {String} source query source to get replacement text
 * @param {String} templateDoc JSON string of the template
 * @returns {String} JSON string of the template
 */
const computeFilteredReplacement = (response, name, filter, source, templateDoc) => {
  const regex = new RegExp(`${source.key}\\$.*\\$`, 'g');
  const matches = templateDoc.match(regex);
  matches && matches.forEach((match) => {
    const filterValue = match.split('$')[1];
    const selectedRow = response.data.find((row) => row[filter.key] === filterValue);
    const replacement =
      selectedRow && selectedRow[name] ?
      selectedRow[name] : 
      response.data.length && response.data[0][name] ?
      response.data[0][name] :
      "NONE";
    templateDoc = templateDoc.replace(match, replacement);
  });
  return templateDoc;
};

/**
 * Function to create a clone of same section based on repeat array length
 * @param {Object} response data from com_report_details.csv
 * @param {Object}  templateDoc JSON string of the template
 * @param {Array} pSections sections data from preprocessor
 * @returns {String or NULL} Stringified template or null if condition not met
 */
const computeMulti = (response, templateDoc, pSections) => {
  // we take data[0] as type:multi will be used only for com_report_details.csv which has only one object in data array
  const { mrbTask, mpdTask, ompTaskReference, primaryInterval, secondaryInterval, ternaryInterval } = response.data[0];
  const mrbData = cleanArray(mrbTask, 'mrb');
  const mpdData = cleanArray(mpdTask, 'mpd');
  const ompData = cleanArray(ompTaskReference, 'omp');
  const intervalData = [
    primaryInterval,
    secondaryInterval,
    ternaryInterval,
  ];
  const dossierData =[{
    label: 'dossier',
    prefixedLabel: 'dossier'
  }];
  const { nonSafteyTasks, cardinality : dataCardinality } = response.data[0];
  const TOCSectionsToIgnore = [];
  // To select the repeation list on which repeation to be done based on the repeat condition
  const selectLevel = {
    'mpd': mpdData,
    'mrb': mrbData,
    'omp': ompData,
    'dossier': dossierData
  };
  // To skip the section wise repetation check
  let skipSectionRepetition = false;
  if (mrbData.length <= 1 && mpdData.length <= 1 && ompData?.length <= 1) {
    skipSectionRepetition = true;
  }

  const newTemplate = JSON.parse(templateDoc);
  pSections.forEach((sourceSection) => {
    const ssNames = sourceSection.names;
    const location = ssNames && getTargetSectionIndex(newTemplate,ssNames[0]);
    const repeatCondition = _.find(sourceSection.repeatCondition, ["cardinality",dataCardinality ]);
    const selectedLevel = repeatCondition && _.flatten(repeatCondition.repeatFor.map((item) =>selectLevel[item]));
    // length and cardinality check to ensure that there are more than one MPDs / MRBs
    if (selectedLevel && validateSectionNames(location, ssNames, newTemplate) && !skipSectionRepetition) {
      const newSectionList = computeSection(
        selectedLevel,
        newTemplate,
        ssNames,
        TOCSectionsToIgnore,
        nonSafteyTasks,
        repeatCondition
      );
      newTemplate.sections.splice(location, ssNames.length, ...newSectionList);
    }

    // secondLevelRepetition if currently only used if we need to duplicate a section by intervals
    const secondLevelRepetition = sourceSection.secondLevelRepetition;
    if (secondLevelRepetition) {
      let slSectionNames = [];
      secondLevelRepetition.names.forEach( (name) => {
        slSectionNames.push(getTargetSectionsNames(newTemplate,name));
      })
      slSectionNames = _.zip(...slSectionNames);
      const secondLevelRepeatType = secondLevelRepetition.repeatType;

      if (secondLevelRepeatType === 'intervals') {
        slSectionNames.forEach( (sectionNames, nameIndex) => {
          const slLocation = getTargetSectionIndex(newTemplate, sectionNames[0]);
          const newSlSectionList = computeSectionByIntervals(
            newTemplate,
            sectionNames,
            TOCSectionsToIgnore,
            nameIndex,
            intervalData,
          );
          newTemplate.sections.splice(slLocation, sectionNames.length, ...newSlSectionList);
        });
      }
    }
  });

  updateTableOfContent(newTemplate, TOCSectionsToIgnore);
  return JSON.stringify(newTemplate, null, 2);
};

/**
 *  Function to compute new sections
 * @param {Array} selectedData array of Objects containing label and prefixed label
 * @param {Object} newTemplate Template JSON object
 * @param {Array} ssNames Array of section names to be repeated in group
 * @param {Array} TOCSectionsToIgnore array of section names to be ignored in TOC
 * @param {Array} nonSafteyTasks list of non saftey mpds and mrbs
 * @param {Object} repeatCondition object containing repeat condition from template selected based on dossier cardinality
 * @returns {Array} array of new sections
 */
const computeSection = (selectedData, newTemplate, ssNames, TOCSectionsToIgnore, nonSafteyTasks, repeatCondition) => {
  return selectedData.reduce((acc,filterQuery, fi) => {
    ssNames.forEach((ssName) => {
      acc.push(frameNewSection(newTemplate, ssName, TOCSectionsToIgnore, filterQuery, fi, nonSafteyTasks, repeatCondition));
    });
    return acc;
  },[]);
};

/**
 *  Function to compute new sections for each available intervals
 * @param {Object} newTemplate Template JSON object
 * @param {Array} slSectionName Array of section names to be repeated in group
 * @param {Array} TOCSectionsToIgnore array of section names to be ignored in TOC
 * @param {number} nameIndex index of the section to update in the list of sections for this section name
 * @param {Array} intervalData array containing the primary, secondary and tertiary interval value for this dossier
 * @returns {Array} array of new sections
 */
const computeSectionByIntervals = (newTemplate, slSectionNames, TOCSectionsToIgnore, nameIndex, intervalData) => {
  const selectedData = [
    {
      label: 'Primary Interval',
      display_label: 'Primary Interval',
      data_label: 'Primary_Interval',
    },
    {
      label: 'Secondary Interval',
      display_label: 'Secondary Interval',
      data_label: 'Secondary_Interval',
    },
    {
      label: 'Ternary Interval',
      display_label: 'Tertiary Interval',
      data_label: 'Ternary_Interval',
    },
  ];
  return selectedData.reduce((acc,filterQuery, filterIndex) => {
    if (intervalData[filterIndex] !== "N/A") {
      slSectionNames.forEach((slSectionName) => {
        acc.push(frameNewIntervalSection(newTemplate, slSectionName, TOCSectionsToIgnore, filterQuery, filterIndex, nameIndex));
      });
    }
    return acc;
  },[]);
};

/**
 * Function to split mpd / MRB string based on , separator
 * and removes N/A values and returns array of object
 * with label and prefix label, sorted by prefixed label
 * @param {object} data source data
 * @param {string} prefix prefix value that needs to be appended
 * @returns {Array} final cleaned array
 */
const cleanArray = (data, prefix) => data && data.split(',').filter(item => item !== 'N/A').map(item => ({
  label: `${prefix.toUpperCase()} Task ${item.trim()}`,
  prefixedLabel: `${prefix}_${item.trim()}`
})).sort((a, b) => a.prefixedLabel.localeCompare(b.prefixedLabel));

/**
 * Function to validate if the group repeat sections are adjacent sections
 * @param {number} startSectionIndex Index of the first section in the array
 * @param {Array} ssNames Array of section names to be repeated in group
 * @param {Object} newTemplate Template
 * @returns {Boolean}
 */
const validateSectionNames = ((startSectionIndex, ssNames, newTemplate) => {
  return startSectionIndex > -1 && ssNames.every((ssName, index) => {
    return getTargetSectionIndex(newTemplate,ssName) > -1 &&
      newTemplate.sections[startSectionIndex + index] &&
      newTemplate.sections[startSectionIndex + index].name === ssName
  });
});

/**
 * Function to update the ignore list inside table of contents section
 * @param {Object} newTemplate Template JSON object
 * @param {Array} listToIgnore Array of section names to be ignored in table of content
 */
const updateTableOfContent = (newTemplate, listToIgnore) => {
  const tocConfig = getToCConfig(newTemplate, "tableofcontents");
  if (tocConfig) {
    tocConfig.details.ignore = tocConfig.details.ignore.concat(listToIgnore);
  }
};

/**
 * fuction to find the index of the target section in the template Json
 * @param {Object} newTemplate Template object
 * @param {String} ssName Name of the section for which index to be found
 * @returns {number} returns index number of the target section
 */
const getTargetSectionIndex = (newTemplate, ssName) => {
  return newTemplate.sections.findIndex(
    (section) => section.name === ssName
  );
};

/**
 * fuction to find the complete names of the target section in the template Json
 * Will return all the names of the target section, even the ones which
 * were repeated.
 * @param {Object} newTemplate Template object
 * @param {String} ssName Name of the section for which index to be found
 * @returns {number} returns index number of the target section
 */
const getTargetSectionsNames = (newTemplate, slSectionName) => {
  return newTemplate.sections.map( (section) => {
    if(section.name === slSectionName || section.name.startsWith(slSectionName + '_')) {
      return section.name
    }
    return undefined;
  }).filter(section => section !== undefined);
}

/**
 * Function to add filter condtion to contents
 * @param {Array} targetContents contents inside a section for which filter condition to be added
 * @param {Object} filterQuery Object containing prefixed label
 * @param {Array} nonSafteyTasks list of non safety mpds and mrbs
 * @param {Object} repeatCondition object containing repeat condition from template selected based on dossier cardinality
 * @returns {Array} updated contents array
 */
const getnewContent = (targetContents,filterQuery,nonSafteyTasks, repeatCondition)=>{
  return targetContents.map((oldContent) => {
    const newContent = _.cloneDeep(oldContent);
    const filters = newContent.sources[0].value.filters !== undefined ? newContent.sources[0].value.filters : []
    filters.push(`aggLevel eq ${filterQuery.prefixedLabel}`)
    newContent.sources[0].value.filters = filters;
    newContent.hidden =
      nonSafteyTasks.indexOf(filterQuery.prefixedLabel) > -1
        ? false
        : isContentHidden(newContent, filterQuery, repeatCondition)
        ? true
        : newContent.hidden;

    return newContent;
  });
};

/**
 * Function to add filter condtion to contents of new interval sections
 * @param {Array} targetContents contents inside a section for which filter condition to be added
 * @param {Object} filterQuery Object containing prefixed label
 * @returns {Array} updated contents array
 */
 const getnewIntervalsContent = (targetContents,filterQuery)=>{
  return targetContents.map((oldContent) => {
    const newContent = _.cloneDeep(oldContent);
    const filters = newContent.sources[0].value.filters !== undefined ? newContent.sources[0].value.filters : []
    filters.push(`interval_type eqifexists ${filterQuery.data_label}`)
    newContent.sources[0].value.filters = filters;
    return newContent;
  });
};

/**
 * Function to create new section from exisiting section
 * @param {Object} newTemplate Template data
 * @param {String} ssName soruce section name
 * @param {Array} TOCSectionsToIgnore array of section names to be ignored in TOC
 * @param {Object} filterQuery Object containing label and prefixed label
 * @param {number} fi index of filter
 * @param {Array} nonSafteyTasks list of non safety mpds and mrbs
 * @param {Object} repeatCondition object containing repeat condition from template selected based on dossier cardinality
 * @returns {Object} returns new section object
 */
const frameNewSection = (newTemplate, ssName,TOCSectionsToIgnore, filterQuery, fi, nonSafteyTasks, repeatCondition) => {
  const location = getTargetSectionIndex(newTemplate, ssName);
  const templateSection = newTemplate.sections[location];
  const {
    contents: targetContents,
    name,
    label,
    ...rest
  } = templateSection;
  const newName = `${name}_${fi}`;
  fi !== 0 && TOCSectionsToIgnore.push(newName);
  return {
    ...rest,
    name: newName,
    label: filterQuery.label === "dossier" ?
      label : `${label} - ${filterQuery.label}`,
    contents: getnewContent(targetContents,filterQuery,nonSafteyTasks, repeatCondition),
  };
};

/**
 * Function to create new section from exisiting section given an interval type
 * @param {Object} newTemplate Template data
 * @param {String} ssName soruce section name
 * @param {Array} TOCSectionsToIgnore array of section names to be ignored in TOC
 * @param {Object} filterQuery Object containing label and prefixed label
 * @param {number} fi index of filter
 * @param {number} nameIndex index of the section to update in the list of sections for this section name
 * @returns {Object} returns new section object
 */
const frameNewIntervalSection = (newTemplate, ssName,TOCSectionsToIgnore, filterQuery, fi, nameIndex) => {
  const location = getTargetSectionIndex(newTemplate, ssName);
  const templateSection = newTemplate.sections[location];
  const {
    contents: targetContents,
    name,
    label,
    ...rest
  } = templateSection;
  const newName = `${name}_${fi}`;
  (nameIndex !== 0 || fi !== 0) && TOCSectionsToIgnore.push(newName);
  let resTemplate = {
    ...rest,
    name: newName,
    label: `${label} (%%INTERVAL_UNIT%%)`,
    intervalType: filterQuery.label,
    contents: getnewIntervalsContent(targetContents, filterQuery),
  };
  resTemplate = JSON.stringify(resTemplate);
  resTemplate = resTemplate.replace(new RegExp(/%%INTERVAL_UNIT%%/, 'g'), `%%INTERVAL_UNIT%%$$${filterQuery.data_label}$$`);
  resTemplate = JSON.parse(resTemplate);
  return resTemplate;
};

/**
 * Function to evaluate if a particular content inside a section needs to be hidden
 * @param {Object} newContent contents object for which hidden needs to be checked
 * @param {Object} filterQuery Object containing label and prefixed label
 * @param {Object} repeatCondition object containing repeat condition from template selected based on dossier cardinality
 * @returns {Boolean} returns true or false
 */
const isContentHidden = (newContent, filterQuery, repeatCondition) => {
  if (repeatCondition.hiddenContent) {
    return (
      newContent.name === repeatCondition.hiddenContent.name &&
      repeatCondition.hiddenContent.condition ===
        filterQuery.prefixedLabel.split('_')[0]
    );
  } else {
    return false;
  }
};
